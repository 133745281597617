<template>
  <LGeoJson v-if="directionDisplay"
            :geojson="directionDisplay"
            :options-style="optionsStyle"/>
</template>

<script>
import { LGeoJson } from 'vue2-leaflet'
import { mapState, mapActions } from 'vuex'
// import geojsonMerge from '@mapbox/geojson-merge'

export default {
  components: {
    LGeoJson
  },
  props: {
    showReturnWay: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      optionsStyle: {
        color: '#243243',
        weight: 7
      }
    }
  },
  computed: {
    ...mapState({
      featureCollectionWay0: state => state.authorization.featureCollectionWay0,
      featureCollectionWay1: state => state.authorization.featureCollectionWay1,
      directionDisplay: state => state.authorization.directionDisplay
    })
  },
  methods: {
    ...mapActions({
      getDirection: 'authorization/getDirection'
    })
  },
  watch: {
    showReturnWay () {
      console.log('BOUH watch showReturnWay')
      this.getDirection(this.showReturnWay)
    },
    featureCollectionWay0 () {
      console.log('BOUH watch featureCollectionWay0')
      this.getDirection(this.showReturnWay)
    },
    featureCollectionWay1 () {
      // Not needed because state changes
      // console.log('BOUH watch featureCollectionWay1')
      // this.getDirection(this.showReturnWay)
    }
  },
  mounted () {
    this.getDirection(this.showReturnWay)
    // when watching changes in the store feature collection, we take care of
    // not calling ORS when it is not needed. For exemple we do not call ORS
    // if only the step details have changed.
    // this.unwatch = this.$store.watch(
    //   state => state.authorization.featureCollectionWay0, // TODO: Use state.authorization.featureCollectionWay1
    //   (newFeatureCollection, oldFeatureCollection) => {
    //     if (![
    //       'authorizations.steps.create',
    //       'authorizations.steps.create-next',
    //       'authorizations.steps.edit'
    //     ].includes(this.$route.name)) {
    //       // we are not on page that can modify the steps, we refresh the
    //       // direction in any case
    //       this.getDirection()
    //       return
    //     }
    //     // no need to refresh the direction if we are creating the first step
    //     if (oldFeatureCollection.features.length === 0) return
    //     // by default we assume we will not refresh the direction
    //     let mustRefresh = false
    //     // we use some instead of forEach to allow breaking the loop
    //     newFeatureCollection.features.some(newFeature => {
    //       // retrieve the old version of each step
    //       const oldFeature = oldFeatureCollection.features.find(old => {
    //         return old.properties.num_step === newFeature.properties.num_step
    //           && old.properties.way === newFeature.properties.way
    //       })
    //       if (!oldFeature) {
    //         // if oldFeature is undefined, it means that we have added a new
    //         // step, so we must refresh the direction
    //         mustRefresh = true
    //         // break the loop
    //         return true
    //       } else if (oldFeature.properties.latitude !== newFeature.properties.latitude
    //         || oldFeature.properties.longitude !== newFeature.properties.longitude) {
    //         // if latitude or longitude has changed between old and new version
    //         // of a step, we must refresh the direction
    //         mustRefresh = true
    //         // break the loop
    //         return true
    //       }
    //     })
    //     // refresh the direction only if there was a change of latitude or
    //     // longitude in any step
    //     if (mustRefresh) this.getDirection(this.showReturnWay)
    //   })
  },
  beforeDestroy () {
    // this.unwatch()
  }
}
</script>
