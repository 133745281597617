<template>
  <LMarker ref="marker"
           @ready="onReady"
           :lat-lng="latLng"
           :draggable="draggable"
           :z-index-offset="zIndex"
           :visible="visible">
    <LIcon :icon-anchor="iconAnchor"
           :icon-size="iconSize">
      <div class="step-icon">
        <img :src="iconUrl"
             alt=""
             class="step-icon-svg">
        <div class="step-icon-number">
          {{ step.properties.num_step }}
        </div>
      </div>
    </LIcon>
  </LMarker>
</template>

<script>
import { LMarker, LIcon } from 'vue2-leaflet'
import { addressFromLatLng } from '@/api/openrouteservice'
import { featureToStepGeoJson } from '@/utils'
import geojsonExtent from '@mapbox/geojson-extent'

export default {
  components: {
    LMarker,
    LIcon
  },
  props: {
    step: {
      type: Object,
      required: true
    },
    authorization: {
      type: Object,
      required: true
    },
    zIndex: {
      type: Number,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      marker: null,
      hackCount: 0,
      isDragging: false,
      iconSize: [58, 71],
      iconAnchor: [29, 71] // first number is half the icon width
    }
  },
  computed: {
    latLng () {
      return [this.step.geometry.coordinates[1], this.step.geometry.coordinates[0]]
    },
    draggable () {
      return this.step.properties.draggable
    },
    iconUrl () {
      if (this.step.properties.way === 1) {
        return this.isDragging ? '/images/step-point-on-blue.svg' : '/images/step-point-off-blue.svg'
      }
      return this.isDragging ? '/images/step-point-on.svg' : '/images/step-point-off.svg'
    }
  },
  methods: {
    onReady (marker) {
      this.marker = marker
      if (!this.draggable) return
      marker.on('dragstart', () => {
        this.isDragging = true
      })
      marker.on('dragend', event => {
        this.isDragging = false
        this.onDragEnd(event.target.getLatLng())
      })
    },
    onDragEnd (latLng) {
      addressFromLatLng(latLng.lat, latLng.lng)
        .then(response => {
          let feature = null
          if (response.data.features.length === 0) {
            feature = { properties: { label: null }, geometry: { coordinates: [latLng.lat, latLng.lng], type: 'Point' } }
          } else {
            feature = response.data.features[0]
          }
          const step = featureToStepGeoJson(
            feature,
            this.authorization,
            true, // draggable
            this.step.properties.way,
            this.step // existingStep
          )
          // to force the usage of ORS feature coordinates, comment the
          // following 3 lines.
          step.geometry.coordinates = [latLng.lng, latLng.lat]
          step.properties.latitude = latLng.lat
          step.properties.longitude = latLng.lng
          step.properties.num_step = this.step.properties.num_step
          step.properties.page_number = this.step.properties.page_number
          step.properties.comment = this.step.properties.comment
          step.properties.use_ors = this.step.properties.use_ors
          this.$emit('dragged', geojsonExtent.bboxify(step))
        })
        .catch(error => alert(error))
    }
  },
  beforeUpdate () {
    // dirty hack permit update of step between 2 steps...
    if (this.marker && this.hackCount === 0) {
      // and a second hack to avoid listeners cloning
      this.marker.off('dragstart')
      this.marker.off('dragend')
      this.onReady(this.marker)
      this.hackCount = this.hackCount + 1
    }
  }
}
</script>
