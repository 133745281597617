<template>
  <button @click="toggle"
          class="hide-return-way btn btn-primary">
    {{ text }}
  </button>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      showReturnWay: true
    }
  },
  computed: {
    ...mapState({
      steps: state => state.authorization.steps
    }),
    text () {
      return this.showReturnWay ? 'Masquer le retour' : 'Afficher le retour'
    }
  },
  methods: {
    toggle () {
      this.showReturnWay = !this.showReturnWay
      this.$emit('show-return-way', this.showReturnWay)
    }
  }
}
</script>

<style scoped lang="scss">
  .hide-return-way {
    font-size: 12px;
    padding: 5px 10px;
  }
</style>
