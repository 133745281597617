import client from '@/api/openrouteservice/client'

/**
 * Returns a JSON formatted list of objects corresponding to the search input.
 *
 * @see https://openrouteservice.org/dev/#/api-docs/geocode/autocomplete/get
 * @param {string} text Name of location, street address or postal code.
 * @param {string} layers Restrict search to layers (place type). By default
 * all layers are searched.
 * @returns {Promise<Object>}
 */
export function autocomplete (text, layers = 'address,locality') {
  return client.get('/geocode/autocomplete', {
    params: {
      text,
      layers
    }
  })
}

/**
 * Returns a JSON formatted list of objects corresponding to the search input.
 *
 * @see https://openrouteservice.org/dev/#/api-docs/geocode/search/get
 * @param {string} text Name of location, street address or postal code.
 * @returns {Promise<Object>}
 */
export function latLngFromAddress (text) {
  return client.get('/geocode/search', {
    params: {
      text,
      layers: 'venue,address,street,locality'
    }
  })
}

/**
 * Returns the next enclosing object with an address tag which surrounds the
 * given coordinate.
 *
 * @see https://openrouteservice.org/dev/#/api-docs/geocode/reverse/get
 * @param {number} lat Latitude of the coordinate to query.
 * @param {number} lng Longitude of the coordinate to query.
 * @returns {Promise<Object>}
 */
export function addressFromLatLng (lat, lng) {
  return client.get('/geocode/reverse', {
    params: {
      'point.lon': lng,
      'point.lat': lat,
      layers: 'venue,address,street,locality'
    }
  })
}
