<template>
  <div class="authorization-map">
    <LMap ref="map"
          @ready="onReady"
          @update:center="onUpdateCenter"
          @update:bounds="onUpdateBounds"
          id="map"
          :zoom="zoom"
          :options="mapOptions"
          :bounds="bounds">
      <LTileLayer :url="url"
                  attribution=""/>
      <LControlZoom position="topright"/>
      <LControl v-if="showHideReturnWayControl"
                position="topleft">
        <ReturnWayToggler @show-return-way="setShowReturnWay"/>
      </LControl>
      <StepMarker v-for="step in steps"
                  :key="`${step.properties.num_step}${step.properties.way}`"
                  :step="step"
                  :authorization="authorization"
                  :z-index="stepZIndex(step)"
                  @dragged="updateStep"
                  :visible="showMarker(step)"/>
      <StepsDirection :show-return-way="showReturnWay"/>
    </LMap>
  </div>
</template>

<script>
import StepMarker from '@/components/StepMarker'
import StepsDirection from '@/components/StepsDirection'
import ReturnWayToggler from '@/components/ReturnWayToggler'
import { bboxToBounds } from '@/utils'
import { LMap, LTileLayer, LControl, LControlZoom } from 'vue2-leaflet'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    StepMarker,
    StepsDirection,
    ReturnWayToggler,
    LMap,
    LTileLayer,
    LControl,
    LControlZoom
  },
  props: {
    center: {
      type: Array,
      required: false
    },
    zoom: {
      type: Number,
      required: false,
      default: 13
    },
    viewStep: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      url: process.env.VUE_APP_TILE_SERVER_URL,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
        attributionControl: false
      },
      showMap: true,
      startBounds: null,
      showReturnWay: true
    }
  },
  computed: {
    ...mapState({
      authorization: state => state.authorization.authorization,
      steps: state => state.authorization.steps,
      lastStepAdded: state => state.authorization.lastStepAdded,
      featureCollection: state => state.authorization.featureCollectionWay0 // TODO: Use state.authorization.featureCollectionWay1
    }),
    bounds () {
      if (this.center && this.center.length === 2) {
        // this.center is a latlng [lat, lng]
        return [this.center, this.center]
      } else if (this.center && this.center.length === 4) {
        // this.center is a bbox [a, b, c, d]
        return bboxToBounds(this.center)
      } else if (this.featureCollection.bbox !== null
        && this.featureCollection.bbox.length === 4) {
        return bboxToBounds(this.featureCollection.bbox)
      } else {
        // fallback, but should never be called
        return null
      }
    },
    hasReturnWaySteps () {
      return !!this.steps.find(step => step.properties.way === 1)
    },
    showHideReturnWayControl () {
      return [
        'authorizations.show',
        'authorizations.validate',
        'authorizations.steps.index'
      ].includes(this.$route.name)
        && this.hasReturnWaySteps
    }
  },
  methods: {
    ...mapActions({
      updateStep: 'authorization/updateStep'
    }),
    onReady (map) {
      this.$emit('map-ready', map)
    },
    onUpdateCenter (map) {
      this.$emit('map-update-center', map)
    },
    onUpdateBounds (map) {
      this.$emit('map-update-bounds', map)
    },
    stepZIndex (step) {
      if (this.$route.name === 'authorizations.steps.edit'
        && step.properties.num_step === parseInt(this.$route.params.num_step)) {
        return 9999
      } else if (step.properties.way === 0) {
        return 999 + step.properties.num_step
      } else {
        return 111 + step.properties.num_step
      }
    },
    showMarker (step) {
      if (this.showReturnWay) {
        return true
      } else {
        return step.properties.way !== 1
      }
    },
    setShowReturnWay (value) {
      this.showReturnWay = value
    }
  }
}
</script>

<style scoped>
  .authorization-map {
    height: 100%;
  }

  #map {
    height: 100%;
    width: 100%;
  }
</style>
