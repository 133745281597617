import axios from 'axios'

function getToken () {
  const tokens = process.env.VUE_APP_ORS_TOKENS.split(',')
  return tokens[Math.floor(Math.random() * tokens.length)]
}

// create a new client instance with default config
const client = axios.create({
  baseURL: 'https://api.openrouteservice.org/',
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  timeout: 30000
})

// create an interceptor on all client requests
client.interceptors.request.use(config => {
  if (config.method === 'post') {
    // if the request method is POST, we set the Authorization header
    config.headers.Authorization = getToken()
  } else {
    // otherwise, we set the api_key parameter
    config.params.api_key = getToken()
  }
  return config
}, error => Promise.reject(error))

export default client
